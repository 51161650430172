import React, { Component, useContext, FunctionComponent, PropsWithChildren } from 'react';
import { ProductVariantFieldsFragment } from '../../../client/__generated__/graphql-client-types';
import { GTMEvent } from '../../helpers/analytics/gtm/gtm-types';
import { buildGTMSelectVariant, buildGTMViewProductDetails } from '../../helpers/analytics/gtm/gtm-utils.helper';
import { ProductFamily } from '../../types/product.types';
import { AnalyticsContext } from '../analytics-context/analytics.context';
import { ProductFamilyContext } from '../product-family/product-family.context';

type VariantContextState = {
	currentVariant: ProductVariantFieldsFragment;
	selectedVariant: ProductVariantFieldsFragment;
	setHoveredVariant: Function;
	setSelectedVariant: Function;
	/*
	 * for some products the initially selected variant will be "invalid"
	 * we want to wait for this potentially invalid variant to be corrected before firing analytics
	 * this state  is for that purpose
	 */
	isSelectedVariantConfirmed: boolean;
	confirmSelectedVariant: Function;
};

/**
 * The props reuse the same fields on the product variant fragment instead of
 * providing one field for the entire product variant object.  This way if some
 * initial variant field changes (mainly the shipping messages) we can properly
 * receive these updates as props will change.  If we passed the full object
 * then the reference to that object never changes and the component is then
 * unaware of changes it should be reacting to.
 */
type VariantContextProviderProps = { onSelection?: Function } & ProductVariantFieldsFragment;

const getInitialVariant = (props: VariantContextProviderProps): ProductVariantFieldsFragment => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { onSelection, ...initialVariant } = props;
	return initialVariant;
};

export const initialState: VariantContextState = {
	currentVariant: {} as any,
	selectedVariant: {} as any,
	setHoveredVariant: () => {},
	setSelectedVariant: () => {},
	isSelectedVariantConfirmed: false,
	confirmSelectedVariant: () => {}
};

export const VariantContext = React.createContext<VariantContextState>(initialState);

/*
 * Functional Component Wrapper
 * Needed to consume multiple parent contexts
 */
export const VariantContextProvider: FunctionComponent<PropsWithChildren<VariantContextProviderProps>> = ({
	children,
	...remainingProps
}) => {
	const { productFamily } = useContext(ProductFamilyContext);
	const { trackEvent } = useContext(AnalyticsContext);
	return (
		<VariantContextProviderClass trackEvent={trackEvent} productFamily={productFamily} {...remainingProps}>
			{children}
		</VariantContextProviderClass>
	);
};

type VariantContextProviderClassProps = {
	trackEvent: (event: GTMEvent) => void;
	productFamily: ProductFamily;
} & VariantContextProviderProps;

/*
 * Provides the Variant Context
 * Handles various life cycle events and fires analytics appropriately
 * Implemented as a class component originally for historical reasons.
 * This could be converted to a function component.
 */
class VariantContextProviderClass extends Component<PropsWithChildren<VariantContextProviderClassProps>, VariantContextState> {
	readonly state: VariantContextState = {
		setSelectedVariant: (selectedVariant: ProductVariantFieldsFragment) => {
			this.setState(() => ({ selectedVariant, currentVariant: selectedVariant }));
		},
		setHoveredVariant: (hoveredVariant: ProductVariantFieldsFragment) => {
			this.setState((state) => ({ currentVariant: hoveredVariant || state.selectedVariant }));
		},
		selectedVariant: getInitialVariant(this.props),
		currentVariant: getInitialVariant(this.props),
		isSelectedVariantConfirmed: false,
		confirmSelectedVariant: () => this.setState({ isSelectedVariantConfirmed: true })
	};

	componentDidMount() {
		// initial load
		this.props.trackEvent(buildGTMViewProductDetails(this.state.selectedVariant, this.props.productFamily));
	}

	componentDidUpdate(prevProps, prevState) {
		/**
		 * The message contains zipcode specific data from the services so if we
		 * see a change in the message we want to update the state from props.
		 * We also check for sale restrictions changing due to zip code.
		 */
		if (
			prevProps.estimatedDeliveryMessage !== this.props.estimatedDeliveryMessage ||
			prevProps.saleRestrictions !== this.props.saleRestrictions ||
			prevProps.shippingCutoffMessage !== this.props.shippingCutoffMessage ||
			prevProps.id !== this.props.id
		) {
			const selection = getInitialVariant(this.props);
			this.state.setSelectedVariant(selection);
			return;
		}

		if (prevState.selectedVariant) {
			// variant has changed
			if (prevState.selectedVariant.id !== this.state.selectedVariant?.id) {
				// new variant is different than previous
				this.props.trackEvent(buildGTMSelectVariant(this.state.selectedVariant, this.props.productFamily));
				if (this.props.onSelection) {
					this.props.onSelection(this.state.selectedVariant);
				}
			}
		}
	}

	render() {
		return <VariantContext.Provider value={this.state}>{this.props.children}</VariantContext.Provider>;
	}
}
