import React, { FunctionComponent } from 'react';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { StyledButton } from '../../buttons';
import { ClickablePhoneNumber } from '../../common-components/clickable-phone-number/clickable-phone-number.component';
import { Popover } from '../../common-components/popover/popover.component';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';
import { HOME_CATEGORY_PHONE_NUMBERS } from '../../../constants/general';
export type ContactToPurchasePopoverProps = {
	isContactToPurchaseOpen: boolean;
	setIsContactToPurchaseOpen: React.Dispatch<React.SetStateAction<boolean>>;
	loading: boolean;
	label: string;
	disabled?: boolean;
	showOnBottom?: boolean;
};
export const ContactToPurchasePopover: FunctionComponent<ContactToPurchasePopoverProps> = ({
	isContactToPurchaseOpen,
	setIsContactToPurchaseOpen,
	disabled,
	loading,
	label,
	showOnBottom
}) => {
	const liveChat = useLiveChat();

	return (
		<Popover
			isVisible={isContactToPurchaseOpen}
			setIsVisible={setIsContactToPurchaseOpen}
			toggleElementClassName="w-100"
			parentElementClassName="flex"
			popOverClassName="br2"
			direction={showOnBottom ? 'bottom' : 'top'}
			toggleElement={
				<StyledButton disabled={disabled || loading} automationHook={'add-to-cart'}>
					<span className="nowrap">{label}</span>
				</StyledButton>
			}>
			<div className="pa1 f6 lh-copy w5">
				<div className="b bb b--theme-tertiary mb2">{label}</div>
				<div className="flex">
					<div className="b mr1">CALL: </div>
					<ClickablePhoneNumber phoneNumber={HOME_CATEGORY_PHONE_NUMBERS.appliances} />
				</div>
				<div className="flex">
					<div className="b mr1">CHAT: </div>
					<div
						className="underline pointer"
						role="button"
						tabIndex={0}
						onClick={liveChat}
						onKeyDown={handleKeys(['Enter', ' '], liveChat)}>
						Click to Chat
					</div>
				</div>
			</div>
		</Popover>
	);
};
